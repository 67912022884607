import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Button from '@mui/material/Button';

import { useHistory } from 'react-router-dom';

function ResultsFinishPage({ match }) {
  useEffect(() => {
    alert('결과를 재확인 하시려면, reload버튼을 눌러주세요.');
  });

  let history = useHistory();

  const formId = match.params.formId;
  const publisherId = match.params.publisherId;

  const onReloadClicked = () => {
    history.push(`/${publisherId}/to/${formId}/results`);
  };

  return (
    <Container
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Container
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '32px',
        }}
      >
        <FacebookIcon color='primary' fontSize='large' sx={{ fontSize: 40 }} />
        <TwitterIcon
          fontSize='large'
          style={{
            backgroundColor: 'rgba(88, 171, 241,1)',
            color: 'rgba(255,255,255,1)',
            // width: '26.25',
            // height: '26.25',
          }}
          sx={{ fontSize: 30 }}
        />
        <LinkedInIcon fontSize='large' color='success' sx={{ fontSize: 40 }} />
      </Container>
      <Button
        onClick={() => onReloadClicked()}
        style={{
          backgroundColor: 'rgba(4,69,175,1 )',
          fontSize: '24px',
          lineHeight: '24px',
          fontWeight: 'bold',
          fontFamily: 'sans-serif',
          fontVariant: 'normal',
          textTransform: 'lowercase',
          width: '107px',
          height: '48px',
        }}
        variant='contained'
      >
        reload
      </Button>
    </Container>
  );
}

export default ResultsFinishPage;
