import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import '../scratchMiniformPage/ScratchMiniformPage';
import {
  ArrowRightOutlined,
  CheckOutlined,
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';

import { useHistory } from 'react-router-dom';
import { red } from '@mui/material/colors';

function ResultsPage({ match }) {
  let history = useHistory();

  const [miniform, setminiform] = useState({});
  const [questions, setquestions] = useState([]);
  const [choices, setchoices] = useState([]);
  const [questionNo, setquestionNo] = useState(1);
  const [renderingQuestion, setrenderingQuestion] = useState([
    { title: 'tite', description: 'discription' },
  ]);
  const [isQuestionChanged, setisQuestionChanged] = useState(false);
  const [selectedChoice, setselectedChoice] = useState({});
  const [focusedChoice, setfocusedChoice] = useState({});
  const [answers, setanswers] = useState([]);

  const [submittedAnswers, setsubmittedAnswers] = useState([]);

  const formId = match.params.formId;
  const publisherId = match.params.publisherId;

  useEffect(() => {
    const variabeles = {
      _id: formId,
    };

    Axios.post(
      'https://fform022.link/api/miniform/getMinifomById',
      variabeles
    ).then((response) => {
      if (response.data.success) {
        setminiform(response.data.miniform);
      } else {
        alert('미니폼 가져오기에 실패했습니다.');
      }
    });

    const variabeles2 = {
      miniformId: formId,
    };

    Axios.post(
      'https://fform022.link/api/miniform/getQuestions',
      variabeles2
    ).then((response) => {
      if (response.data.success) {
        setquestions(response.data.questions);
        setrenderingQuestion(
          response.data.questions.filter((question, index) => index === 0)
        );
      } else {
        console.log(response.data.err);
        alert('질문 목록 정보 가져오기에 실패하였습니다.');
      }
    });

    const variabeles3 = {
      miniformId: formId,
    };

    Axios.post(
      'https://fform022.link/api/miniform/getThisMiniformChoices',
      variabeles3
    ).then((response) => {
      if (response.data.success) {
        setchoices(response.data.choices);
      } else {
        console.log(response.data.err);
        alert('선택지 목록 정보 가져오기에 실패하였습니다.');
      }
    });

    const variabeles4 = {
      miniformId: formId,
      publisherId: publisherId,
    };

    Axios.post(
      'https://fform022.link/api/miniform/getThisMiniformMyAnswers',
      variabeles4
    ).then((response) => {
      if (response.data.success) {
        setanswers(response.data.answers);
      } else {
        console.log(response.data.err);
        alert('나의 답변 목록 정보 가져오기에 실패하였습니다.');
      }
    });

    const variabeles5 = {
      miniformId: formId,
      isSubmitted: true,
    };

    Axios.post(
      'https://fform022.link/api/miniform/getSubmittedAnswers',
      variabeles5
    ).then((response) => {
      if (response.data.success) {
        console.log(response.data.answers);
        setsubmittedAnswers(response.data.answers);
      } else {
        console.log(response.data.err);
        alert('답변 목록 정보 가져오기에 실패하였습니다.');
      }
    });
  }, []);

  const onAnswer = () => {
    if (questionNo <= questions.length) {
      setquestionNo(questionNo + 1);
    } else {
      setquestionNo(questions.length);
    }
    setrenderingQuestion(
      questions.filter((question, index) => index === questionNo)
    );

    setisQuestionChanged(true);

    setTimeout(() => {
      setisQuestionChanged(false);
    }, 400);

    // alert('next question');
  };

  const onSubmit = () => {
    console.log('asdS');
    alert('마지막 질문입니다.');
    history.push(`/${publisherId}/to/${formId}/results/finish`);
  };

  const onDownQuesion = () => {
    if (questionNo <= questions.length) {
      setquestionNo(questionNo + 1);
    } else {
      setquestionNo(questions.length);
    }
    setrenderingQuestion(
      questions.filter((question, index) => index === questionNo - 1)
    );
  };

  const onUpQuesion = () => {
    console.log('onUpQuestion');
    if (questionNo > 1) {
      setquestionNo(questionNo - 1);
    } else {
      setquestionNo(1);
    }
    setrenderingQuestion(
      questions.filter((question, index) => index === questionNo - 1)
    );
  };

  const renderChoices = choices
    .filter((choice, index) => choice.questionId === renderingQuestion[0]._id)
    .map((choice, index) => {
      const onChoiceClicked = (choice) => {
        if (selectedChoice._id === choice._id) {
          setselectedChoice({});
          setfocusedChoice(choice);
          console.log('focused choice : ' + choice._id);
        } else {
          if (focusedChoice._id === choice._id) {
            setselectedChoice(choice);
            console.log('selected choice : ' + choice._id);
          } else {
            setfocusedChoice(choice);
            console.log('focused choice : ' + choice._id);
          }
        }
      };

      return (
        <div
          // onClick={() => onChoiceClicked(choice)}
          data-value-string='01F5ADE9MFTG6MAFD2D64J6JXG'
          aria-checked='false'
          role='radio'
          tabIndex='0'
          aria-label='Terrific!'
          aria-disabled='false'
          className='radio-list__Radio-xzv3or-0 dVwazc'
          style={{ marginBottom: '8px', marginRight: '0px', width: '100%' }}
        >
          <div
            aria-hidden='true'
            className='radio-list__Wrapper-xzv3or-0 iuALq'
          >
            <div
              aria-disabled='false'
              className={`base-choice__Root-sc-5koeqf-0 ${
                selectedChoice._id === choice._id ? 'cJzDcc' : 'duzJXd'
              }`}
              data-force-focused={`${
                focusedChoice._id === choice._id ? 'true' : 'false'
              }`}
              data-qa='choice'
              data-qa-index='0'
              data-qa-selected={`${
                selectedChoice._id === choice._id ? 'true' : 'false'
              }`}
              data-selectable='true'
            >
              <div className='key-helper__Root-p7s0zv-0 hVHJPn'>
                <div
                  aria-hidden='true'
                  className={`key-helper__KeyHelperWrapper-p7s0zv-1 ${
                    selectedChoice._id === choice._id ? 'uhXCP' : 'vHAEh'
                  }`}
                >
                  <div className='key-helper__HintWrapper-p7s0zv-5 jTvEmQ'>
                    <div
                      className={`key-helper__Hint-p7s0zv-3 ${
                        selectedChoice._id === choice._id ? 'wpGYW' : 'bisXkO'
                      }`}
                    >
                      <span className='key-helper__Letter-p7s0zv-2 kAJTkZ'>
                        {String.fromCharCode(65 + index)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='checkbox-choice__ChoiceContent-m4g23g-1 erBFfO'>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                  className='text__TextWrapper-sc-1t2ribu-0 jMCaoH'
                >
                  {choice.choiceContent + '  '}
                  <p
                    style={{
                      color: 'red',
                      lineHeight: '28px',
                      maxHeight: '40px',
                      margin: '0px',
                      float: 'right',
                    }}
                  >
                    {
                      submittedAnswers.filter(
                        (submittedAnswer) =>
                          submittedAnswer.choiceId === choice._id
                      ).length
                    }
                  </p>
                </div>
              </div>
              <div
                className={`checkbox-choice__CheckboxContent-m4g23g-2 ${
                  selectedChoice._id === choice._id ? 'klswuM' : 'klswuN'
                }`}
              >
                <CheckOutlined className='icon__Boundary-sc-2r3yc0-0 fIQOlV' />
              </div>
            </div>
          </div>
        </div>
      );
    });

  const answerPercent =
    questionNo - 1 === questions.length
      ? 100
      : (Number(questionNo - 1) / Number(questions.length)) * 100;

  const renderQuestions = questions.map((question, index) => {
    return (
      <div
        className={`BlockListItem__Wrapper-sc-3hkawb-0 ${
          question._id === renderingQuestion[0]._id ? 'cNSobI' : 'gLFgAZ'
        }`}
      >
        <section className='block-section__LayoutWrapper-vw1wyv-0 bWRnnQ'>
          <div
            data-qa='blocktype-multiple_choice deep-purple-blocktype-multiple_choice'
            data-qa-block='true'
            data-qa-blockref='01F5ADE9MFS6MZY935Y7SQBMX5'
            id='block-01F5ADE9MFS6MZY935Y7SQBMX5'
            className='block-section__Section-vw1wyv-1 cfbEUn'
            data-qa-focused='true'
          >
            <div
              data-qa='block-scroller'
              className='block-scroller__Root-sc-23jz1-0 ePXIqg'
            >
              <div className='block-scroller__Positioner-sc-23jz1-1 hdsXep'>
                <div className='block-structure__Root-sc-1esu8nk-2 eskdrW'>
                  <div className='block-structure__ContentWrapper-sc-1esu8nk-3 hsPGnT'>
                    <div className='layout-container__Root-fb7y4k-0 ihdqbY'>
                      <div className='spacer__Spacer-sc-1fl5ef-0 ebJDnc'>
                        <fieldset className='block-structure__Fieldset-sc-1esu8nk-4 evnNQm'>
                          <legend className='a11y-visually-hidden__A11yVisuallyHidden-sc-19v2sdi-0 ffYKC'>
                            Questtion Identify Nomber
                            <span>Question Title</span>
                            <span>Question Description</span>
                          </legend>
                          <div>
                            <div className='question-header__HeaderWrapper-z8zey9-3 jwzXXR'>
                              <div className='question-header__CounterPosition-z8zey9-1 fNYihY'>
                                <div className='question-header__CounterWrapper-z8zey9-2 fgWWLd'>
                                  <div className='responsive-spacer__SpacerWrapper-sc-2bvre0-0 efAoXt'>
                                    <div
                                      id='header-counter-01F5ADE9MFS6MZY935Y7SQBMX5'
                                      className='question-header__CounterContent-z8zey9-0 gQaiTR'
                                    >
                                      <span
                                        data-qa='question-header-counter'
                                        className='text__TextWrapper-sc-1t2ribu-0 iPJQIO'
                                      >
                                        <span aria-hidden='true'>
                                          {questionNo}
                                        </span>
                                      </span>
                                      <div className='spacer__Spacer-sc-1fl5ef-0 EYcfb'>
                                        <ArrowRightOutlined />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='text__TextWrapper-sc-1t2ribu-0 fMYKYH'>
                                <span
                                  data-qa='block-title deep-purple-block-title'
                                  aria-hidden='true'
                                  className='text__TextWrapper-sc-1t2ribu-0 hbZNOg'
                                >
                                  <span>{renderingQuestion[0].title}</span>
                                </span>
                              </div>
                            </div>
                            <div className='responsive-spacer__SpacerWrapper-sc-2bvre0-0 bRptqN'>
                              <p
                                data-qa='block-description'
                                aria-hidden='true'
                                id='multiple_choice-01F5ADE9MFS6MZY935Y7SQBMX5-8zwrZWoqw94BZQZO-description'
                                className='text__TextWrapper-sc-1t2ribu-0 gVBPJu'
                              >
                                <span>{renderingQuestion[0].description}</span>
                                <span style={{ color: 'red' }}>
                                  {'   total ' +
                                    submittedAnswers.filter(
                                      (submittedAnswer) =>
                                        submittedAnswer.questionId ===
                                        renderingQuestion[0]._id
                                    ).length +
                                    ' answers submitted'}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className='responsive-spacer__SpacerWrapper-sc-2bvre0-0 iFWjeU'>
                            <div className='responsive-spacer__SpacerWrapper-sc-2bvre0-0 elVKhz'>
                              <div role='radiogroup'>
                                <div
                                  className='choices-layout__StyledDiv-qpux4o-0 fUNCth'
                                  style={{ position: 'relative' }}
                                >
                                  <div
                                    data-qa='choice-list'
                                    className='choices-layout__ChoicesLayoutWrapper-qpux4o-1 ackLp'
                                  >
                                    {renderChoices}
                                  </div>
                                  <div
                                    className='erd_scroll_detection_container erd_scroll_detection_container_animation_active'
                                    style={{
                                      visibility: 'hidden',
                                      display: 'inline',
                                      width: '0px',
                                      height: '0px',
                                      zIndex: '-1',
                                      overflow: 'hidden',
                                      margin: '0px',
                                      padding: '0px',
                                    }}
                                  >
                                    <div
                                      dir='ltr'
                                      className='erd_scroll_detection_container'
                                      style={{
                                        position: 'absolute',
                                        flex: '0 0 auto',
                                        overflow: 'hidden',
                                        zIndex: '-1',
                                        visibility: 'hidden',
                                        width: '100%',
                                        height: '100%',
                                        left: '0px',
                                        top: '0px',
                                      }}
                                    >
                                      <div
                                        className='erd_scroll_detection_container'
                                        style={{
                                          position: 'absolute',
                                          flex: '0 0 auto',
                                          overflow: 'hidden',
                                          zIndex: '-1',
                                          visibility: 'hidden',
                                          inset: '-18px -17px -17px -18px',
                                        }}
                                      >
                                        <div
                                          style={{
                                            position: 'absolute',
                                            left: '0px',
                                            top: '0px',
                                            width: '397px',
                                            height: '181px',
                                          }}
                                        >
                                          <div
                                            style={{
                                              position: 'absolute',
                                              left: '0px',
                                              top: '0px',
                                              width: '397px',
                                              height: '181px',
                                            }}
                                          ></div>
                                        </div>
                                        <div
                                          style={{
                                            position: 'absolute',
                                            flex: '0 0 auto',
                                            overflow: 'scroll',
                                            zIndex: '-1',
                                            visibility: 'hidden',
                                            width: '100%',
                                            height: '100%',
                                          }}
                                        >
                                          <div
                                            style={{
                                              position: 'absolute',
                                              width: '200%',
                                              height: '200%',
                                            }}
                                          ></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='block-footer__Root-gb8zj2-0 dhiTWl'>
                                <div className='responsive-spacer__SpacerWrapper-sc-2bvre0-0 ghDFUa'>
                                  <div data-qa-button-visible='true'>
                                    <div className='block-footer__SubmitButtonFooter-gb8zj2-1 fcJHvN'>
                                      <div
                                        className='animate__AnimateStyled-nw4u3g-0 butqJF'
                                        hidden
                                      >
                                        <div className='distribute__Distribute-sc-5km20m-0 jPqjwV'>
                                          <div className='submit__Root-sc-1ra8r4w-0 kYgMyf'>
                                            <div className='submit__SubmitWrapper-sc-1ra8r4w-2 gZUhqA'>
                                              <button
                                                data-qa='submit-button deep-purple-submit-button'
                                                disabled
                                                tabIndex='0'
                                                className='button__ButtonWrapper-sc-1g3rldj-0 fnBYGM'
                                              >
                                                <span className='button__FlexWrapper-sc-1g3rldj-1 dcBBsI'>
                                                  <span className='text__TextWrapper-sc-1t2ribu-0 jMCaoH'>
                                                    Submit
                                                  </span>
                                                </span>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div className='spacer__Spacer-sc-1fl5ef-0 jWHkSw'></div>
                                      </div>
                                    </div>
                                    <div className='animate__AnimateStyled-nw4u3g-0 jFfXbt'>
                                      <div className='submit__Root-sc-1ra8r4w-0 kYgMyf'>
                                        <div className='submit__SubmitWrapper-sc-1ra8r4w-2 gZUhqA'>
                                          <button
                                            style={{
                                              display:
                                                questions.length > questionNo
                                                  ? 'flex'
                                                  : 'none',
                                            }}
                                            onClick={() => onAnswer()}
                                            data-qa='ok-button-visible deep-purple-ok-button-visible'
                                            tabIndex='0'
                                            className='button__ButtonWrapper-sc-1g3rldj-0 jgOIrr'
                                          >
                                            <span className='button__FlexWrapper-sc-1g3rldj-1 dcBBsI'>
                                              <span className='button__ButtonTextWrapper-sc-1g3rldj-4 bsRDYy'>
                                                <span className='text__TextWrapper-sc-1t2ribu-0 jMCaoH'>
                                                  {questions.length <=
                                                  questionNo
                                                    ? 'FINISH'
                                                    : 'NEXT'}
                                                </span>
                                              </span>
                                              <span className='spacer__Spacer-sc-1fl5ef-0 eLvVn'>
                                                <span className='button__IconWrapper-sc-1g3rldj-5 gUjpSX'>
                                                  <CheckOutlined className='icon__Boundary-sc-2r3yc0-0 hREAAg' />
                                                </span>
                                              </span>
                                            </span>
                                          </button>
                                          <button
                                            style={{
                                              display:
                                                questions.length <= questionNo
                                                  ? 'flex'
                                                  : 'none',
                                            }}
                                            onClick={() => onSubmit()}
                                            data-qa='ok-button-visible deep-purple-ok-button-visible'
                                            tabIndex='0'
                                            className='button__ButtonWrapper-sc-1g3rldj-0 jgOIrr'
                                          >
                                            <span className='button__FlexWrapper-sc-1g3rldj-1 dcBBsI'>
                                              <span className='button__ButtonTextWrapper-sc-1g3rldj-4 bsRDYy'>
                                                <span className='text__TextWrapper-sc-1t2ribu-0 jMCaoH'>
                                                  {questions.length <=
                                                  questionNo
                                                    ? 'FINISH'
                                                    : 'NEXT'}
                                                </span>
                                              </span>
                                              <span className='spacer__Spacer-sc-1fl5ef-0 eLvVn'>
                                                <span className='button__IconWrapper-sc-1g3rldj-5 gUjpSX'>
                                                  <CheckOutlined className='icon__Boundary-sc-2r3yc0-0 hREAAg' />
                                                </span>
                                              </span>
                                            </span>
                                          </button>
                                          <div
                                            aria-hidden='true'
                                            className='submit__HelperWrapper-sc-1ra8r4w-1 efBMOI'
                                          >
                                            <div className='action-helper__ActionHelperWrapper-xl3y05-0 cUTmGg'>
                                              <div className='action-helper__KeyTextWrapper-xl3y05-1 bxaCdz'>
                                                <div className='text__TextWrapper-sc-1t2ribu-0 beMLwc'>
                                                  press
                                                  <strong>Enter ↵</strong>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  });

  return (
    <main
      data-qa='renderer'
      className='stkv-t-font--system-font'
      style={{ height: '100%', position: 'relative' }}
    >
      <div
        className='base-styles__BaseStyle-sc-1oava9b-0 htZiTp'
        style={{ height: '100%' }}
      >
        <div className='background__Background-esq3f2-0 gHD'></div>
        <div
          className='MouseWheelTrigger__Root-sc-1nbmmxo-0 bfpZqi'
          style={{ height: '100%' }}
        >
          <div style={{ height: '100%' }}>
            <div
              aria-hidden='false'
              id='stkv-form-root'
              className='Form__Root-sc-18c4ntz-0 hygFdK'
            >
              <div
                aria-hidden='true'
                data-qa-stickyheader-visible='false'
                className='StickyHeaderContainer__Wrapper-sc-1tjdqrs-0 iosmky'
              >
                <div className='helper-header__HelperHeaderWrapper-gf2ih3-0 gaQRgD'>
                  <div className='layout-container__Root-fb7y4k-0 dztwgo'>
                    <div className='helper-header__Sections-gf2ih3-1 iQbFWD'>
                      <div className='helper-header__HeadersSection-gf2ih3-2 fJEnI'>
                        <div
                          className='animate__AnimateStyled-nw4u3g-0 butqJF'
                          hidden
                        >
                          <div className='spacer__Spacer-sc-1fl5ef-0 hJcsjD'>
                            <div className='question-header__HeaderWrapper-z8zey9-3 jwzXXR'>
                              <div className='question-header__CounterPosition-z8zey9-1 fNYihY'>
                                <div className='question-header__CounterWrapper-z8zey9-2 fgWWLd'>
                                  <div className='responsive-spacer__SpacerWrapper-sc-2bvre0-0 efAoXt'>
                                    <div className='question-header__CounterContent-z8zey9-0 gQaiTR'>
                                      <span
                                        data-qa='question-header-counter'
                                        className='text__TextWrapper-sc-1t2ribu-0 iPJQIO'
                                      >
                                        <span aria-hidden='true'></span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                data-qa='helper-header-group-title'
                                className='text__TextWrapper-sc-1t2ribu-0 jgVFke'
                              >
                                <div>&nbsp;</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className='animate__AnimateStyled-nw4u3g-0 butqJF'
                          hidden
                        >
                          <div className='question-header__HeaderWrapper-z8zey9-3 jwzXXR'>
                            <div className='question-header__CounterPosition-z8zey9-1 fNYihY'>
                              <div className='question-header__CounterWrapper-z8zey9-2 fgWWLd'>
                                <div className='responsive-spacer__SpacerWrapper-sc-2bvre0-0 efAoXt'>
                                  <div className='question-header__CounterContent-z8zey9-0 gQaiTR'>
                                    <span
                                      data-qa='question-header-counter'
                                      className='text__TextWrapper-sc-1t2ribu-0 iPJQIO'
                                    >
                                      <span aria-hidden='true'></span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              data-qa='helper-header-block-title'
                              className='text__TextWrapper-sc-1t2ribu-0 jgVFke'
                            >
                              <div>&nbsp;</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className='animate__AnimateStyled-nw4u3g-0 butqJF'
                        hidden
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='BlockList__Root-ny54fa-0 kfhEwh'>
                <div className='blocks__Root-sc-1biwhcv-0 unGyY'>
                  {renderQuestions}
                  {/*  next questions .. */}
                  <div className='BlockListItem__Wrapper-sc-3hkawb-0 gLFgAZ'></div>
                  <div className='BlockListItem__Wrapper-sc-3hkawb-0 gLFgAZ'></div>
                  <div className='blocks__LayoutWrapper-sc-1biwhcv-1 iPdsbL'>
                    {(questionNo !== 1 || questionNo !== questions.length) && (
                      <div
                        style={{
                          display: isQuestionChanged ? 'block' : 'none',
                          transition: 'all 200ms ease 0s',
                          opacity: '0',
                        }}
                      >
                        <div className='block-media__Wrapper-sc-13u0f85-0 dHOndN'>
                          <div className='block-media__AttachmentWrapper-sc-13u0f85-1 qsooi'>
                            <div className='block-media__FilterWrapper-sc-13u0f85-2 bnBX'>
                              <picture
                                className='image__StyledPicture-p7rduw-0 gLSMQa'
                                style={{
                                  margin: '0px auto',
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'cover',
                                  objectPosition: '50% 50%',
                                }}
                              >
                                <source
                                  srcSet='https://images.typeform.com/images/skRiMR4C7YLC/image/default-firstframe.png'
                                  media='(prefers-reduced-motion: reduce)'
                                ></source>
                                <img
                                  data-qa-loaded='true'
                                  src='https://images.typeform.com/images/skRiMR4C7YLC/image/default-firstframe.png'
                                  style={{
                                    display: 'block',
                                    margin: '0px auto',
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    objectPosition: '50% 50%',
                                  }}
                                  alt='no src'
                                ></img>
                              </picture>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      style={{
                        display: 'block',
                        transition: 'all 200ms ease 0s',
                        opacity: '1',
                      }}
                    >
                      <div className='block-media__Wrapper-sc-13u0f85-0 dHOndN'>
                        <div className='block-media__AttachmentWrapper-sc-13u0f85-1 qsooi'>
                          <div className='block-media__FilterWrapper-sc-13u0f85-2 bnBX'>
                            <picture
                              className={`image__StyledPicture-p7rduw-0 gLSMQa ${
                                isQuestionChanged ? 'blink' : ''
                              }`}
                              style={{
                                margin: '0px auto',
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                objectPosition: '50% 50%',
                              }}
                            >
                              <source
                                srcSet={`https://images.typeform.com/images/${
                                  questionNo % 2 == 0
                                    ? 'zqi2pRghr7cE'
                                    : 'skRiMR4C7YLC'
                                }/image/default-firstframe.png`}
                                media='(prefers-reduced-motion: reduce)'
                              ></source>
                              <img
                                data-qa-loaded='true'
                                src={`https://images.typeform.com/images/${
                                  questionNo % 2 == 0
                                    ? 'zqi2pRghr7cE'
                                    : 'skRiMR4C7YLC'
                                }/image/default-firstframe.png`}
                                style={{
                                  display: 'block',
                                  margin: '0px auto',
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'cover',
                                  objectPosition: '50% 50%',
                                }}
                                alt='no src'
                              ></img>
                            </picture>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'none',
                        transition: 'all 200ms ease 0s',
                        opacity: isQuestionChanged ? '1' : '0',
                      }}
                    >
                      <div className='block-media__Wrapper-sc-13u0f85-0 dHOndN'>
                        <div className='block-media__AttachmentWrapper-sc-13u0f85-1 qsooi'>
                          <div className='block-media__FilterWrapper-sc-13u0f85-2 bnBX'>
                            <picture
                              className='image__StyledPicture-p7rduw-0 gLSMQa '
                              style={{
                                // display: 'block',
                                margin: '0px auto',
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                objectPosition: '50% 50%',
                              }}
                            >
                              <source
                                srcSet='https://images.typeform.com/images/skRiMR4C7YLC/image/default-firstframe.png'
                                media='(prefers-reduced-motion: reduce)'
                              ></source>
                              <img
                                data-qa-loaded='true'
                                src='https://images.typeform.com/images/skRiMR4C7YLC/image/default-firstframe.png'
                                style={{
                                  display: 'block',
                                  margin: '0px auto',
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'cover',
                                  objectPosition: '50% 50%',
                                }}
                                alt='no src'
                              ></img>
                            </picture>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                aria-hidden='true'
                className={`StickyFooter__StickyFooterPlaceholder-sc-1ud3eur-1 ${
                  isQuestionChanged ? 'hfZoCm' : 'rcbKk'
                }`}
              >
                <div
                  className={`animate__AnimateStyled-nw4u3g-0 ${
                    isQuestionChanged ? 'hfZoCm' : 'jFfXbt'
                  }`}
                >
                  <div
                    data-qa='sticky-footer'
                    data-qa-visible='true'
                    className='StickyFooter__StickyFooterWrapper-sc-1ud3eur-2 jyEsmD'
                  >
                    <div className='layout-container__Root-fb7y4k-0 dztwgo'>
                      <div className='StickyFooter__CommitButtonWrapper-sc-1ud3eur-0 cYQTLn'>
                        <button
                          style={{
                            display:
                              questions.length > questionNo ? 'flex' : 'none',
                          }}
                          onClick={() => onAnswer()}
                          data-qa='sticky-footer-button'
                          className='button__ButtonWrapper-sc-1g3rldj-0 jgOIrr'
                        >
                          <span className='button__FlexWrapper-sc-1g3rldj-1 dcBBsI'>
                            <span className='button__ButtonTextWrapper-sc-1g3rldj-4 bsRDYy'>
                              <span className='text__TextWrapper-sc-1t2ribu-0 jMCaoH'>
                                {questions.length <= questionNo
                                  ? 'FINISH'
                                  : 'NEXT'}
                              </span>
                            </span>
                            <span className='spacer__Spacer-sc-1fl5ef-0 eLvVn'>
                              <span className='button__IconWrapper-sc-1g3rldj-5 gUjpSX'>
                                <CheckOutlined className='icon__Boundary-sc-2r3yc0-0 hREAAg' />
                              </span>
                            </span>
                          </span>
                        </button>
                        <button
                          style={{
                            display:
                              questions.length <= questionNo ? 'flex' : 'none',
                          }}
                          onClick={() => onSubmit()}
                          data-qa='ok-button-visible deep-purple-ok-button-visible'
                          tabIndex='0'
                          className='button__ButtonWrapper-sc-1g3rldj-0 jgOIrr'
                        >
                          <span className='button__FlexWrapper-sc-1g3rldj-1 dcBBsI'>
                            <span className='button__ButtonTextWrapper-sc-1g3rldj-4 bsRDYy'>
                              <span className='text__TextWrapper-sc-1t2ribu-0 jMCaoH'>
                                {questions.length <= questionNo
                                  ? 'FINISH'
                                  : 'NEXT'}
                              </span>
                            </span>
                            <span className='spacer__Spacer-sc-1fl5ef-0 eLvVn'>
                              <span className='button__IconWrapper-sc-1g3rldj-5 gUjpSX'>
                                <CheckOutlined className='icon__Boundary-sc-2r3yc0-0 hREAAg' />
                              </span>
                            </span>
                          </span>
                        </button>
                        <div className='spacer__Spacer-sc-1fl5ef-0 iAbfjs'>
                          <div className='action-helper__ActionHelperWrapper-xl3y05-0 cUTmGg'>
                            <div className='action-helper__KeyTextWrapper-xl3y05-1 bxaCdz'>
                              <div className='text__TextWrapper-sc-1t2ribu-0 sBaZm'>
                                press
                                <strong> Enter ↵</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='animate__AnimateStyled-nw4u3g-0 jFfXbt'>
                <div
                  aria-hidden='false'
                  className='Form__FooterWrapper-sc-18c4ntz-1 ilmxKH'
                >
                  <div
                    data-qa='fixed-footer'
                    className='footer__FooterWrapper-sc-1bn1rrm-0 glQRTM'
                  >
                    <div className='footer__ButtonsWrapper-sc-1bn1rrm-6 czNna'>
                      <nav className='footer__Navigation-sc-1bn1rrm-8 bpoVRn'>
                        {/* <button
                          style={{
                            display:
                              questions.length === questionNo
                                ? 'none'
                                : 'block',
                            opacity:
                              questions.length === questionNo ? '0' : '1',
                          }}
                          onClick={() => onDownQuesion()}
                          data-qa='fixed-footer-navigation-next'
                          aria-label='Navigate to next question'
                          data-tracking='footer_arrow_down'
                          className={`button__ButtonWrapper-sc-1g3rldj-0 ${
                            questionNo === questions.length
                              ? 'cjBOdH'
                              : 'clMUTA'
                          }`}
                        >
                          <span className='button__FlexWrapper-sc-1g3rldj-1 kxQpTn'>
                            <span className='button__ButtonTextWrapper-sc-1g3rldj-4 bsRDYy'>
                              <span className='text__TextWrapper-sc-1t2ribu-0 jQGcPH'></span>
                            </span>
                            <span className='spacer__Spacer-sc-1fl5ef-0 iiksEZ'>
                              <span className='button__IconWrapper-sc-1g3rldj-5 gUjpSX'>
                                <DownOutlined className='icon__Boundary-sc-2r3yc0-0 hREAAg' />
                              </span>
                            </span>
                          </span>
                        </button>
                        <button
                          style={{
                            display:
                              questions.length === questionNo
                                ? 'block'
                                : 'none',
                            opacity:
                              questions.length === questionNo ? '1' : '0',
                          }}
                          onClick={() => onDownQuesion()}
                          data-qa='fixed-footer-navigation-next'
                          aria-label='Navigate to next question'
                          data-tracking='footer_arrow_down'
                          className='button__ButtonWrapper-sc-1g3rldj-0 cjBOdH'
                          disabled
                        >
                          <span className='button__FlexWrapper-sc-1g3rldj-1 kxQpTn'>
                            <span className='button__ButtonTextWrapper-sc-1g3rldj-4 bsRDYy'>
                              <span className='text__TextWrapper-sc-1t2ribu-0 jQGcPH'></span>
                            </span>
                            <span className='spacer__Spacer-sc-1fl5ef-0 iiksEZ'>
                              <span className='button__IconWrapper-sc-1g3rldj-5 gUjpSX'>
                                <DownOutlined className='icon__Boundary-sc-2r3yc0-0 gVvKot' />
                              </span>
                            </span>
                          </span>
                        </button>
                        <div className='footer__ButtonDivider-sc-1bn1rrm-7 glgqBq'></div>
                        <button
                          style={{
                            display: questionNo <= 1 ? 'none' : 'inline',
                          }}
                          onClick={() => onUpQuesion()}
                          data-qa='fixed-footer-navigation-previous'
                          aria-label='Navigate to previous question'
                          data-tracking='footer_arrow_up'
                          className={`button__ButtonWrapper-sc-1g3rldj-0 ${
                            questionNo <= 1 ? 'eelsan' : 'ggfpAw'
                          }`}
                        >
                          <span className='button__FlexWrapper-sc-1g3rldj-1 kxQpTn'>
                            <span className='button__ButtonTextWrapper-sc-1g3rldj-4 bsRDYy'>
                              <span className='text__TextWrapper-sc-1t2ribu-0 jQGcPH'></span>
                            </span>
                            <span className='spacer__Spacer-sc-1fl5ef-0 iiksEZ'>
                              <span className='button__IconWrapper-sc-1g3rldj-5 gUjpSX'>
                                <UpOutlined className='icon__Boundary-sc-2r3yc0-0 hREAAg' />
                              </span>
                            </span>
                          </span>
                        </button>
                        <button
                          style={{
                            display: questionNo <= 1 ? 'inline' : 'none',
                          }}
                          onClick={() => onUpQuesion()}
                          data-qa='fixed-footer-navigation-previous'
                          aria-label='Navigate to previous question'
                          data-tracking='footer_arrow_up'
                          className={`button__ButtonWrapper-sc-1g3rldj-0 ${
                            questionNo <= 1 ? 'eelsan' : 'ggfpAw'
                          }`}
                          disabled
                        >
                          <span className='button__FlexWrapper-sc-1g3rldj-1 kxQpTn'>
                            <span className='button__ButtonTextWrapper-sc-1g3rldj-4 bsRDYy'>
                              <span className='text__TextWrapper-sc-1t2ribu-0 jQGcPH'></span>
                            </span>
                            <span className='spacer__Spacer-sc-1fl5ef-0 iiksEZ'>
                              <span className='button__IconWrapper-sc-1g3rldj-5 gUjpSX'>
                                <UpOutlined className='icon__Boundary-sc-2r3yc0-0 gVvKot' />
                              </span>
                            </span>
                          </span>
                        </button> */}
                      </nav>
                      <div className='footer__ButtonDivider-sc-1bn1rrm-7 glgqBq'></div>
                      <a
                        href='https://fform022.link/'
                        rel='noopener noreferrer'
                        target='_blank'
                        className='button__ButtonWrapper-sc-1g3rldj-0 fqCrb footer__BrandButton-sc-1bn1rrm-9 futohW'
                      >
                        <span className='button__FlexWrapper-sc-1g3rldj-1 dcBBsI'>
                          <span className='button__ButtonTextWrapper-sc-1g3rldj-4 bsRDYy'>
                            <span className='text__TextWrapper-sc-1t2ribu-0 jQGcPH'>
                              <span data-qa='fixed-footer-brand-button'>
                                <span className='text__TextWrapper-sc-1t2ribu-0 jyWAYZ'>
                                  Powered by
                                  <strong> Fform*.link </strong>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </a>
                    </div>
                    <div
                      data-qa='fixed-footer-progress'
                      className='footer__ProgressWrapper-sc-1bn1rrm-1 intGoI'
                    >
                      <div
                        aria-live='polite'
                        data-qa='fixed-footer-progress-label'
                        id='footer-progress-label'
                        className='footer__ProgressLabel-sc-1bn1rrm-2 frmGpf'
                      >
                        {`${Number(questionNo - 1)} of ${
                          questions.length
                        } answered`}
                      </div>
                      <div
                        aria-labelledby='footer-progress-label'
                        aria-valuemax='100'
                        aria-valuemin='0'
                        aria-valuenow='17'
                        data-qa='fixed-footer-progress-bar'
                        role='progressbar'
                        className='footer__ProgressBar-sc-1bn1rrm-3 ePZvDb'
                      >
                        <div className='footer__ProgressTrack-sc-1bn1rrm-4 haZQyz'></div>
                        <div
                          className='footer__ProgressFill-sc-1bn1rrm-5 fXRcxZ'
                          style={{ width: `${answerPercent}%` }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className='erd_scroll_detection_container erd_scroll_detection_container_animation_active'
        style={{
          visibility: 'hidden',
          display: 'inline',
          width: '0px',
          height: '0px',
          zIndex: '-1',
          overflow: 'hidden',
          margin: '0px',
          padding: '0px',
        }}
      >
        <div
          dir='ltr'
          className='erd_scroll_detection_container'
          style={{
            position: 'absolute',
            flex: '0 0 auto',
            overflow: 'hidden',
            zIndex: '-1',
            visibility: 'hidden',
            width: '100%',
            height: '100%',
            left: '0px',
            top: '0px',
          }}
        >
          <div
            className='erd_scroll_detection_container'
            style={{
              position: 'absolute',
              flex: '0 0 auto',
              overflow: 'hidden',
              zIndex: '-1',
              visibility: 'hidden',
              inset: '-18px -17px -17px -18px',
            }}
          >
            <div
              style={{
                position: 'absolute',
                flex: '0 0 auto',
                overflow: 'scroll',
                zIndex: '-1',
                visibility: 'hidden',
                width: '100%',
                height: '100%',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  left: '0px',
                  top: '0px',
                  width: '1070px',
                  height: '350px',
                }}
              ></div>
            </div>
            <div
              style={{
                position: 'absolute',
                flex: '0 0 auto',
                overflow: 'scroll',
                zIndex: '-1',
                visibility: 'hidden',
                width: '100%',
                height: '100%',
              }}
            >
              <div
                style={{ position: 'absolute', width: '200%', height: '200%' }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
export default ResultsPage;
