import logo from './logo.svg';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import NotFoundPage from './NotFoundPage';
import ScratchMiniformPage from './components/views/scratchMiniformPage/ScratchMiniformPage';
import submitCompletePage from './components/views/submitCompletePage/submitCompletePage';
import resultsPage from './components/views/resultsPage/resultsPage';
import ResultsFinishPage from './components/views/resultsFinishPage/resultsFinishPage';
// import ChatPage from './components/views/ChatPage/Containers/ChatPage';

import './App.css';

function App() {
  // useEffect(() => {
  //   fetch("http://15.165.237.7:5000/api/miniform/getMinifoms")
  //     .then(res => {
  //       return res.json();
  //     })
  //     .then(data => {
  //       console.log(data);
  //       data.miniforms.map((miniform, index)=>{
  //         console.log(miniform._id);
  //       })
  //     })
  // }, []);

  return (
    <Router>
      {/* <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div> */}
      <Switch>
        <Route exact path='/' component={NotFoundPage} />
        {/* <Route exact path='/**' component={NotFoundPage} />
        <Route exact path='/to/:formId' component={NotFoundPage} /> */}
        <Route
          exact
          path='/:publisherId/to/:formId'
          component={ScratchMiniformPage}
        />
        <Route
          exact
          path='/:publisherId/to/:formId/submit'
          component={submitCompletePage}
        />
        <Route
          exact
          path='/:publisherId/to/:formId/results'
          component={resultsPage}
        />
        <Route
          exact
          path='/:publisherId/to/:formId/results/finish'
          component={ResultsFinishPage}
        />
        {/* <Route path='/ChatPage' component={ChatPage} /> */}
      </Switch>
    </Router>
  );
}

export default App;
